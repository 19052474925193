export default function Price({ hasBg }) {
  return (
    <>
      <section
        id="pricing"
        className={`package-section`}
      >
        <div className={`container accordions pt-100 pb-60`}>
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading mb-5 text-center my-5 mt-lg-0">
                <h3>
                  Luôn miễn phí trải nghiệm mọi tính năng
                </h3>
                <p className="lead">
                  KiDoSo cấp miễn phí 10.000 credit (tương đương 50.000 VNĐ) mỗi tháng để bạn trải nghiệm dịch vụ. Nếu bạn sử dụng nhiều hơn, vui lòng thanh toán thêm khoản chi phí phát sinh để chúng tôi có thể duy trì dịch vụ cho bạn.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img
                  alt="placeholder"
                  src="assets/img/price_table.png"
                  className="img-center img-fluid"
                />
              </div>
            </div>
          </div>
          
          <div className="mt-4 text-center">
            {/* <p className="mb-2">Quy đổi hiện tại <strong>1 credit = 5 VNĐ</strong></p> */}
            <div>Đừng lo chi phí, luôn có chương trình khuyến mại khi bạn nạp Credit, chiết khấu tới 40% khi nạp trước</div>
            <p className="mb-2">
              Nếu bạn cần nhiều thông tin hơn?
              <a href="https://www.facebook.com/kidoso.vn" className="color-secondary">
                {" "}
                Liên hệ chúng tôi{" "}
              </a>
            </p>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-lg-8 text-center">
              <h5>Ví dụ cách tính phí khi sử dụng</h5>
            </div>
            <div className="col-lg-8">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img
                  alt="placeholder"
                  src="assets/img/example_fee.png"
                  className="img-center img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
